import React from "react"
import PropTypes from "prop-types"

import Tooltip from "@src/components/Tooltip"
import Type from "@nerdwallet/react-typography/structured-content"

import styles from "./DriverCell.module.less"

const DriverCell = ({ description, tooltip, value, valueTooltip }) => (
  <>
    <span>{value}</span>
    {tooltip && <Tooltip content={valueTooltip} />}
    <Type small color="neutral-darker" size="1" className={styles.description}>
      {description}
    </Type>
  </>
)

DriverCell.propTypes = {
  description: PropTypes.string,
  tooltip: PropTypes.any,
  value: PropTypes.string,
  valueTooltip: PropTypes.string,
}

export default DriverCell
