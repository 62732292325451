import React, { useState } from "react"
import PropTypes from "prop-types"

import Table from "@src/components/Table"
import Dropdown from "@src/components/Dropdown"
import TableFeatures from "@src/components/roundup/TableFeatures"
import OptionButtons from "@src/components/OptionButtons"
import SummaryTableTitle from "@src/components/SummaryTableTitle"

import { graphql } from "gatsby"
import BuildRow from "./BuildRow"
import { COLUMNS } from "./constants"

import { TOOLTIP_TEXT } from "@src/lib/constants"
import { PRODUCT_TYPES } from "@nerdwallet/nw-api-sdk/marketplace"

const sortData = (data, sortBy) => {
  if (!sortBy) return data

  const sortFunction = COLUMNS.find(i => i.id === sortBy).sort
  const sortedData = data.sort(sortFunction)

  return sortedData
}

const columnsToSort = () => {
  return COLUMNS.filter(i => i.sort).map(col => ({
    key: col.id,
    value: col.heading,
  }))
}

const filterByRating = (data, filterBy) => {
  if (!filterBy || filterBy === "all-star-ratings") return data

  return data.filter(
    ({ marketplaceEntity: { starRating } }) => starRating === 5
  )
}

const filterByAnnualFee = (data, filterBy) => {
  if (!filterBy || filterBy === "all-annual-fees") return data

  return data.filter(({ marketplaceEntity: { annualFee } }) => annualFee === 0)
}

const buildRows = (data, withGoToLink) =>
  data.map(row => BuildRow({ row, withGoToLink }))

const applyFunction = (data, [transformFunction, param]) => {
  return transformFunction(data, param)
}

const chainFunctions = (data, functions) => {
  return functions.reduce(applyFunction, data)
}

const CreditCardSummaryTableSection = ({
  table,
  sort,
  ratingFilter,
  annualFeeFilter,
  withGoToLink,
  productDescription,
}) => {
  const [sortBy, setSortBy] = useState(null)
  const [ratingFilterValue, setRatingFilterValue] = useState("all-star-ratings")
  const [annualFeeFilterValue, setAnnualFeeFilterValue] =
    useState("all-annual-fees")

  const data = chainFunctions(table.rows, [
    [filterByRating, ratingFilterValue],
    [filterByAnnualFee, annualFeeFilterValue],
    [sortData, sortBy],
    [buildRows, withGoToLink],
  ])

  return (
    <>
      <SummaryTableTitle
        isCreditCard
        rowCount={data.length}
        productDescription={productDescription}
      />
      <TableFeatures
        sort={
          sort && (
            <Dropdown
              smallLegend
              boldLegend={false}
              header="Sort by"
              options={columnsToSort()}
              placeholder="Make selection"
              setUpdateAction={setSortBy}
            />
          )
        }
        ratingFilter={
          ratingFilter && (
            <OptionButtons
              header="NerdWallet rating"
              tooltipData={{
                title: "How is this rating determined?",
                content: TOOLTIP_TEXT[PRODUCT_TYPES.CREDIT_CARDS],
              }}
              groupName="ratingFilter"
              options={[
                { id: "all-star-ratings", label: "All" },
                { id: "only-5-stars", label: "5 ☆" },
              ]}
              checkedValue={ratingFilterValue}
              updateCheckedValue={setRatingFilterValue}
            />
          )
        }
        annualFeeFilter={
          annualFeeFilter && (
            <OptionButtons
              header="Annual fee"
              groupName="annualFeeFilter"
              options={[
                { id: "all-annual-fees", label: "All" },
                { id: "no-fee", label: "$0" },
              ]}
              checkedValue={annualFeeFilterValue}
              updateCheckedValue={setAnnualFeeFilterValue}
            />
          )
        }
      />
      <Table columns={COLUMNS} data={data} stickyHeader />
    </>
  )
}

export const query = graphql`
  fragment SummaryTableSection on smbCreditCardRoundup {
    summaryTableSection {
      allowTruncatingRows
      disclaimerText
      marketplaceEntityHeader
      maxRows
      numTruncatedRows
      rows {
        marketplaceEntityId
        categoryName
        marketplaceEntity {
          id
          productType
          ... on CreditCardsOfferV2 {
            name
            displayName
            image {
              source
              alt
            }
            annualFee
            starRating
            monetizable
            institution {
              id
              name
            }
            product {
              id
            }
            reviewLink
            placementDrivers {
              id
              description
              label
              tooltip
              value
              valueTooltip
            }
            cta {
              link
              text
              subtext
              type
            }
            ratesAndFeesCta {
              link
              text
              subtext
              type
            }
          }
        }
        shouldEnableImageLazyLoad
        cta {
          link
        }
        customReviewUrl
        excludeModelExecutionIds
      }
    }
  }
`

CreditCardSummaryTableSection.propTypes = {
  sort: PropTypes.bool,
  ratingFilter: PropTypes.bool,
  annualFeeFilter: PropTypes.bool,
  withGoToLink: PropTypes.bool,
  table: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    disclaimerText: PropTypes.string,
  }).isRequired,
  productDescription: PropTypes.string.isRequired,
}

CreditCardSummaryTableSection.defaultProps = {
  sort: true,
  ratingFilter: false,
  annualFeeFilter: false,
  withGoToLink: false,
}

export default CreditCardSummaryTableSection
