import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Type } from "@nerdwallet/currency"
import styles from "./OptionButtons.module.less"
import Tooltip from "@src/components/Tooltip"

const OptionButtons = props => {
  const {
    groupName,
    options,
    checkedValue,
    updateCheckedValue,
    header,
    tooltipData,
  } = props

  return (
    <fieldset className={styles.fieldset} key={groupName}>
      <legend className={styles.legend}>
        <Type className={styles.header} small size="1" inline>
          {header}
        </Type>
        &nbsp;
        {tooltipData && (
          <Tooltip
            hiddenOnMobile
            title={tooltipData.title}
            content={tooltipData.content}
          />
        )}
      </legend>
      {options.map(({ id, label }) => {
        return (
          <label
            key={id}
            className={classNames(styles.option, {
              [styles.checked]: checkedValue === id,
            })}
            onClick={() => updateCheckedValue(id)}
          >
            {label}
            <input
              className={styles.input}
              type="radio"
              name={groupName}
              value={id}
              data-nw-option={id}
              data-nw-mp
            />
          </label>
        )
      })}
    </fieldset>
  )
}

OptionButtons.propTypes = {
  header: PropTypes.string,
  tooltipData: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  groupName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  checkedValue: PropTypes.string,
  updateCheckedValue: PropTypes.func,
}

OptionButtons.defaultProps = {
  groupName: "groupName",
  options: [
    { id: "all", label: "All" },
    { id: "best", label: "Best" },
  ],
  checkedValue: null,
  updateCheckedValue: args => args,
}

export default OptionButtons
