import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Stars from "@src/components/Stars"
import Button from "@src/components/Button"
import AnchorLink from "@src/components/AnchorLink"
import Type from "@nerdwallet/react-typography/structured-content"

import { slugify, formatRating } from "@src/lib/helpers"

import styles from "./StarRatingCell.module.less"

const Rating = ({ rating }) => (
  <Type small inline color="blue">
    {formatRating(rating)}
  </Type>
)

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
}

const StarRatingCell = ({
  rating,
  link,
  categoryName,
  ctaType,
  entityName,
  withReadMoreButton,
}) => {
  return (
    <>
      {rating && (
        <>
          <Stars
            maxRating={1}
            rating={rating}
            nerdwallet
            className={classNames(styles.container, styles.star)}
          />
          {link ? (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              data-cta-type={ctaType}
            >
              <Rating rating={rating} />
            </a>
          ) : (
            <Rating rating={rating} />
          )}

          <Type
            small
            inline
            color="neutral-darker"
            size="1"
            className={styles.suffix}
          >
            /5
          </Type>
        </>
      )}
      {categoryName && (
        <Type small color="neutral-darker" size="1" className={styles.category}>
          {categoryName}
        </Type>
      )}
      {withReadMoreButton && (
        <AnchorLink
          target={slugify(entityName)}
          dataCy="read-more-button"
          ctaType="CC Summary Jump Link"
        >
          <Button
            primary={false}
            className={classNames("clickable", styles.readMoreButton)}
          >
            Read More
          </Button>
        </AnchorLink>
      )}
    </>
  )
}

StarRatingCell.propTypes = {
  categoryName: PropTypes.string,
  rating: PropTypes.number,
  link: PropTypes.string.isRequired,
  ctaType: PropTypes.string,
  entityName: PropTypes.string,
  withReadMoreButton: PropTypes.bool,
}

StarRatingCell.defaultProps = {
  categoryName: "",
  rating: null,
  ctaType: null,
  entityName: "",
  withReadMoreButton: false,
}

export default StarRatingCell
