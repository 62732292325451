import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { TableSimple } from "@nerdwallet/currency"

import styles from "./Table.module.less"

const Table = ({
  columns,
  data,
  simpleTable,
  showLastColumnOnMobile,
  stickyHeader,
}) => {
  return (
    <TableSimple
      className={classNames(styles.table, styles.tableHeader, {
        [styles.simpleTable]: simpleTable,
        [styles.stickyHeader]: stickyHeader,
        [styles.hideLastColumnOnMobile]: !showLastColumnOnMobile,
      })}
      columns={columns}
      data={data}
    />
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  simpleTable: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  showLastColumnOnMobile: PropTypes.bool,
}

Table.defaultProps = {
  simpleTable: false,
  stickyHeader: false,
  showLastColumnOnMobile: false,
}

export default Table
