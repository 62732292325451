import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Lazy from "react-lazyload"
import Type from "@nerdwallet/react-typography/structured-content"

import styles from "./EntityCell.module.less"

const EntityCell = ({
  imgAlt,
  imgSrc,
  imgClassName,
  imgWrapperClassName,
  text,
  subNodes,
  ctaType,
  withLinks,
  href,
  isMonetizable,
}) => {
  const imgNode = imgSrc && (
    <img
      alt={imgAlt}
      src={imgSrc}
      className={classNames(styles.image, imgClassName)}
    />
  )

  // eslint-disable-next-line react/prop-types
  const MonetizingLink = ({ linkCtaType, children }) => (
    <a
      href={href}
      data-cta-type={linkCtaType}
      target="_blank"
      rel="noopener noreferrer"
      data-mclick={isMonetizable}
    >
      {children}
    </a>
  )

  const getFinalImgNode = () => {
    if (href) {
      return (
        <MonetizingLink linkCtaType={`${ctaType} Image`}>
          {withLinks && (
            <div className={styles.overlayText}>
              <Type size="1">Click here to apply</Type>
            </div>
          )}
          {imgNode}
        </MonetizingLink>
      )
    }

    return imgNode
  }

  const finalImgNode = getFinalImgNode()

  const textNode = withLinks ? (
    <MonetizingLink linkCtaType={`${ctaType} Name`}>
      <Type size="1">{text}</Type>
    </MonetizingLink>
  ) : (
    <Type size="1">{text}</Type>
  )
  return (
    <>
      {imgSrc && <Lazy className={imgWrapperClassName}>{finalImgNode}</Lazy>}
      {textNode}
      {subNodes}
    </>
  )
}

EntityCell.propTypes = {
  imgAlt: PropTypes.string,
  imgSrc: PropTypes.string,
  imgClassName: PropTypes.string,
  imgWrapperClassName: PropTypes.string,
  text: PropTypes.string.isRequired,
  subNodes: PropTypes.node,
  href: PropTypes.string,
  ctaType: PropTypes.string,
  withLinks: PropTypes.bool,
  isMonetizable: PropTypes.bool,
}

EntityCell.defaultProps = {
  imgAlt: "",
  imgSrc: "",
  imgClassName: "",
  imgWrapperClassName: "",
  subNodes: null,
  href: "",
  ctaType: "",
  withLinks: false,
  isMonetizable: false,
}

export default EntityCell
