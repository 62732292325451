import { makeSortFunction } from "@src/lib/helpers"

//The order is important, use for COLUMNS heading
export const PLACEMENT_LABELS = {
  annualFee: "Annual fee",
  rewardRate: "Rewards rate",
  introOffer: "Intro offer",
}

const extractValue = {
  annualFee: row => {
    const placement = row.marketplaceEntity.placementDrivers.find(
      pd => pd.label === PLACEMENT_LABELS.annualFee
    )
    return parseInt(placement.value.replace(/^[^0-9]/g, ""), 10)
  },
  rating: row => row.marketplaceEntity.starRating,
  marketplaceEntity: row => row.marketplaceEntity.displayName,
}

const sortFunction = makeSortFunction(extractValue)

export const COLUMNS = [
  {
    id: "marketplaceEntity",
    heading: "Credit card",
    sort: sortFunction([{ field: "marketplaceEntity" }]),
  },
  {
    id: "rating",
    heading: "NerdWallet rating",
    sort: sortFunction([
      { field: "rating", asc: false },
      { field: "annualFee" },
      { field: "marketplaceEntity" },
    ]),
  },
  {
    id: "annualFee",
    heading: PLACEMENT_LABELS.annualFee,
    sort: sortFunction([
      { field: "annualFee" },
      { field: "rating", asc: false },
      { field: "marketplaceEntity" },
    ]),
  },
  {
    id: "rewardRate",
    heading: PLACEMENT_LABELS.rewardRate,
  },
  {
    id: "introOffer",
    heading: PLACEMENT_LABELS.introOffer,
  },
  {
    id: "learnMore",
    heading: "Learn more",
  },
]
