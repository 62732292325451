export const summaryTableSection = {
  allowTruncatingRows: false,
  disclaimerText: null,
  marketplaceEntityHeader: "Credit Card",
  maxRows: 7,
  numTruncatedRows: 5,
  rows: [
    {
      marketplaceEntityId: "56c06ee4-0ee7-4d3b-9589-08de4e4b80e2",
      categoryName: "Best for Intro APR + cash-back rewards",
      marketplaceEntity: {
        id: "56c06ee4-0ee7-4d3b-9589-08de4e4b80e2",
        productType: "credit_cards",
        name: "American Express Blue Business Cash™ Card",
        displayName: "American Express Blue Business Cash™ Card",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/56c06ee4-0ee7-4d3b-9589-08de4e4b80e2/bf129abdc4dd9a1a940c928200725c028b2ccfc48ef43233505e0e6b5c5c55eb.jpg",
          alt: "American Express® Blue Business Cash Card",
        },
        annualFee: 0,
        starRating: 5,
        institution: {
          id: "cd471c3b-eca7-487c-9a41-ed802b938fca",
          name: "American Express",
        },
        product: { id: "2753a360-906b-4e7c-b3e6-08a0dd8171f6" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/amex-blue-business-cash",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$0",
            valueTooltip: null,
          },
          {
            description: "Cashback",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 2% cash back on all eligible purchases on up to $50,000 per calendar year, then 1%.  Cash back earned is automatically credited to your statement. Terms Apply.",
                  },
                },
              },
            },
            value: "1%-2%",
            valueTooltip:
              "Earn 2% cash back on all eligible purchases on up to $50,000 per calendar year, then 1%.  Cash back earned is automatically credited to your statement. Terms Apply.",
          },
          {
            description: null,
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn a $250 statement credit after you make $3,000 in purchases on your Card in your first 3 months. Terms Apply.",
                  },
                },
              },
            },
            value: "$250",
            valueTooltip:
              "Earn a $250 statement credit after you make $3,000 in purchases on your Card in your first 3 months. Terms Apply.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/56c06ee4-0ee7-4d3b-9589-08de4e4b80e2?name=American-Express-Blue-Business-Cash-Card&clickHeader_category=Credit+Card&clickHeader_productId=65711&mvt=MTY1MDQwMTg1NjM1MDM0NTAzNDoyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOjIwZjJmMGU5NWMzZWNmYjRjZDJlYzdlZmY1M2JjNmI0NDM1N2JiNzRmN2Y0Y2JmNTg5NDljZjMzMjgxMTk0NDg%3D",
          text: "Apply now",
          subtext: "on American Express's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: {
          link: "https://www.nerdwallet.com/redirect/credit-cards/65711?name=American-Express-Blue-Business-Cash-Card&clickHeader_category=Credit+Card&destination_url_field=terms_and_fees_link&clickHeader_productId=65711",
          text: "Rates & Fees",
          subtext: null,
          type: "APPLY_NOW",
        },
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl: null,
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "b5f9962f-019c-4d49-9a1d-8efda7c2baa2",
      categoryName: "Best for Cash back: Bonus categories",
      marketplaceEntity: {
        id: "b5f9962f-019c-4d49-9a1d-8efda7c2baa2",
        productType: "credit_cards",
        name: "Ink Business Cash® Credit Card",
        displayName: "Ink Business Cash® Credit Card",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/b5f9962f-019c-4d49-9a1d-8efda7c2baa2/737df22785f06358da70cc8058c9d7300f135f4e7da2e618ae475e6ca7ec395a.jpg",
          alt: "Chase Ink Business Cash Credit Card Credit Card",
        },
        annualFee: 0,
        starRating: 5,
        institution: {
          id: "2ab43a2f-629a-41a9-b79a-2991eecd4b36",
          name: "Chase",
        },
        product: { id: "70a0657c-8334-4103-bc00-48742c3f0e28" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/chase-ink-cash",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$0",
            valueTooltip: null,
          },
          {
            description: "Cashback",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 5% cash back on the first $25,000 spent in combined purchases at office supply stores and on internet, cable and phone services each account anniversary year, Earn 2% cash back on the first $25,000 spent in combined purchases at gas stations and restaurants each account anniversary year, Earn 1% cash back on all other card purchases with no limit to the amount you can earn",
                  },
                },
              },
            },
            value: "1%-5%",
            valueTooltip:
              "Earn 5% cash back on the first $25,000 spent in combined purchases at office supply stores and on internet, cable and phone services each account anniversary year, Earn 2% cash back on the first $25,000 spent in combined purchases at gas stations and restaurants each account anniversary year, Earn 1% cash back on all other card purchases with no limit to the amount you can earn",
          },
          {
            description: null,
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn $750 bonus cash back after you spend $7,500 on purchases in the first 3 months from account opening.",
                  },
                },
              },
            },
            value: "$750",
            valueTooltip:
              "Earn $750 bonus cash back after you spend $7,500 on purchases in the first 3 months from account opening.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/b5f9962f-019c-4d49-9a1d-8efda7c2baa2?name=Chase-Ink-Cash-Business&clickHeader_category=Credit+Card&clickHeader_productId=3063&mvt=MTY1MDQwMTg1NjM1OTgyOTU5NDoyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOmVmMGIzYmM3NjlkMTgxZWRiNWRmNGMzOWU4MjcxMmRjYzIyMTY1ZDRiMWU3Yzg4ZTJlYTU2NmM2YzgwZGFjNmI%3D",
          text: "Apply now",
          subtext: "on Chase's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: null,
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/chase-ink-cash",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "1dbbf123-a75f-4cd9-8e95-105df8d7db7f",
      categoryName: "Best for Cash back: Flat-rate rewards, no annual fee",
      marketplaceEntity: {
        id: "1dbbf123-a75f-4cd9-8e95-105df8d7db7f",
        productType: "credit_cards",
        name: "Ink Business Unlimited® Credit Card",
        displayName: "Ink Business Unlimited® Credit Card",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/1dbbf123-a75f-4cd9-8e95-105df8d7db7f/96e943d2b5651f5906012499afd8c08782686ae9662916ff51ff7b89ca61f739.jpg",
          alt: "Ink Business Unlimited℠ Credit Card",
        },
        annualFee: 0,
        starRating: 5,
        institution: {
          id: "2ab43a2f-629a-41a9-b79a-2991eecd4b36",
          name: "Chase",
        },
        product: { id: "36ffd417-cfb8-4c42-8208-80f3426029b8" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/chase-ink-business-unlimited",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$0",
            valueTooltip: null,
          },
          {
            description: "Cashback",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn unlimited 1.5% cash back on every purchase made for your business",
                  },
                },
              },
            },
            value: "1.5%",
            valueTooltip:
              "Earn unlimited 1.5% cash back on every purchase made for your business",
          },
          {
            description: null,
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn $750 bonus cash back after you spend $7,500 on purchases in the first 3 months from account opening.",
                  },
                },
              },
            },
            value: "$750",
            valueTooltip:
              "Earn $750 bonus cash back after you spend $7,500 on purchases in the first 3 months from account opening.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/1dbbf123-a75f-4cd9-8e95-105df8d7db7f?name=Chase-Ink-Business-Unlimited&clickHeader_category=Credit+Card&clickHeader_productId=65533&mvt=MTY1MDQwMTg1NjM2MjgyNDE1NToyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOmYwZDliN2YwZjM3ZWViMmNhZTZkYWJmMTU0YjlmYWRlNGQ3ZjcyNjk0ZjUxYTU4ZDhkOWZhMGZhMDlhMTM3ZDk%3D",
          text: "Apply now",
          subtext: "on Chase's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: null,
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/chase-ink-business-unlimited",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "0952eb6d-d5be-40ee-b3d4-22a87da23813",
      categoryName: "Best for Intro APR + AmEx points",
      marketplaceEntity: {
        id: "0952eb6d-d5be-40ee-b3d4-22a87da23813",
        productType: "credit_cards",
        name: "The Blue Business® Plus Credit Card from American Express",
        displayName:
          "The Blue Business® Plus Credit Card from American Express",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/0952eb6d-d5be-40ee-b3d4-22a87da23813/028d9f1fd040233f277958b5497f32f33fe08b0bd1eeccb1cd12f5216a49f7e6.jpg",
          alt: "American Express The Blue for Business® Credit Card",
        },
        annualFee: 0,
        starRating: 4.8,
        institution: {
          id: "cd471c3b-eca7-487c-9a41-ed802b938fca",
          name: "American Express",
        },
        product: { id: "8d41ca3e-d913-47ca-a1ce-eea97def58d9" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/amex-blue-business-plus",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$0",
            valueTooltip: null,
          },
          {
            description: "Points",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 2X Membership Rewards® points on everyday business purchases such as office supplies or client dinners. 2X applies to the first $50,000 in purchases per year, 1 point per dollar thereafter. Terms Apply.",
                  },
                },
              },
            },
            value: "1x-2x",
            valueTooltip:
              "Earn 2X Membership Rewards® points on everyday business purchases such as office supplies or client dinners. 2X applies to the first $50,000 in purchases per year, 1 point per dollar thereafter. Terms Apply.",
          },
          {
            description: "Points",
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 15,000 Membership Rewards® points after you spend $3,000 in eligible purchases on the Card within your first 3 months of Card Membership. Terms Apply.",
                  },
                },
              },
            },
            value: "15,000",
            valueTooltip:
              "Earn 15,000 Membership Rewards® points after you spend $3,000 in eligible purchases on the Card within your first 3 months of Card Membership. Terms Apply.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/0952eb6d-d5be-40ee-b3d4-22a87da23813?name=American-Express-Blue-for-Business&clickHeader_category=Credit+Card&clickHeader_productId=1112&mvt=MTY1MDQwMTg1NjM0NTQ4NTMzOToyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOjY3ZDViZTI4MTViZDE5OGNkZGE1OTBhNGQ1Njc2YWE5OTlmM2EwY2U5M2ZjYTIzZWE0NDY4OGJjNWJlNDJhOGI%3D",
          text: "Apply now",
          subtext: "on American Express's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: {
          link: "https://www.nerdwallet.com/redirect/credit-cards/1112?name=American-Express-Blue-for-Business&clickHeader_category=Credit+Card&destination_url_field=terms_and_fees_link&clickHeader_productId=1112",
          text: "Rates & Fees",
          subtext: null,
          type: "APPLY_NOW",
        },
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/amex-blue-business-plus",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "d05d36d9-82d1-4f03-b589-ffcfd835d41a",
      categoryName: "Best for Travel rewards: Premium rewards and perks",
      marketplaceEntity: {
        id: "d05d36d9-82d1-4f03-b589-ffcfd835d41a",
        productType: "credit_cards",
        name: "The Business Platinum Card® from American Express",
        displayName: "The Business Platinum Card® from American Express",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/d05d36d9-82d1-4f03-b589-ffcfd835d41a/e6f868dd850e5ed1153ecf302ebaa47fe5dc7769e3aa9ddbe5e588e7d2d7e856.jpg",
          alt: "The Business Platinum® Card from American Express OPEN Credit Card",
        },
        annualFee: 695,
        starRating: 5,
        institution: {
          id: "cd471c3b-eca7-487c-9a41-ed802b938fca",
          name: "American Express",
        },
        product: { id: "d5880264-8748-441b-b3e8-a5fd1d5f82ec" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/american-express-business-platinum",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$695",
            valueTooltip: null,
          },
          {
            description: "Points",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 1.5X points on eligible purchases at US construction material & hardware suppliers, electronic goods retailers and software & cloud system providers, and shipping providers, as well as on purchases of $5,000 or more everywhere else, on up to $2 million of these purchases per calendar year. Earn 1X points for each dollar you spend on eligible purchases. Terms Apply.",
                  },
                },
              },
            },
            value: "1x-5x",
            valueTooltip:
              "Earn 1.5X points on eligible purchases at US construction material & hardware suppliers, electronic goods retailers and software & cloud system providers, and shipping providers, as well as on purchases of $5,000 or more everywhere else, on up to $2 million of these purchases per calendar year. Earn 1X points for each dollar you spend on eligible purchases. Terms Apply.",
          },
          {
            description: "Points",
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 120,000 Membership Rewards® points after you spend $15,000 on eligible purchases with the Business Platinum Card within the first 3 months of Card Membership. Terms Apply.",
                  },
                },
              },
            },
            value: "120,000",
            valueTooltip:
              "Earn 120,000 Membership Rewards® points after you spend $15,000 on eligible purchases with the Business Platinum Card within the first 3 months of Card Membership. Terms Apply.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/d05d36d9-82d1-4f03-b589-ffcfd835d41a?name=American-Express-Business-Platinum&clickHeader_category=Credit+Card&clickHeader_productId=1100&mvt=MTY1MDQwMTg1NjM1Njk5ODMwNzoyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOjBmM2IyZTUzMDEzM2ExMTMxNTU5NDRhODgwMGJjMzA2NzIzZWZmMmNiZWZiY2FhODk0OTZhOGYzODM4YTU2ZmI%3D",
          text: "Apply now",
          subtext: "on American Express's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: {
          link: "https://www.nerdwallet.com/redirect/credit-cards/1100?name=American-Express-Business-Platinum&clickHeader_category=Credit+Card&destination_url_field=terms_and_fees_link&clickHeader_productId=1100",
          text: "Rates & Fees",
          subtext: null,
          type: "APPLY_NOW",
        },
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/american-express-business-platinum",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "5e01a478-944d-4a38-9646-dde7cb8321f0",
      categoryName: "Best for Travel rewards: No annual fee",
      marketplaceEntity: {
        id: "5e01a478-944d-4a38-9646-dde7cb8321f0",
        productType: "credit_cards",
        name: "Bank of America® Business Advantage Travel Rewards World Mastercard® credit card",
        displayName:
          "Bank of America® Business Advantage Travel Rewards World Mastercard® credit card",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/5e01a478-944d-4a38-9646-dde7cb8321f0/6f512e82947a698ac4f65494c1b82160e0567b2d4545c27339f1a48a81a302fb.jpg",
          alt: "Bank of America® Business Advantage Travel Rewards World Mastercard® credit card",
        },
        annualFee: 0,
        starRating: 3.9,
        institution: {
          id: "208ee6da-a18f-45aa-afbe-de2f36b1b9ec",
          name: "Bank of America",
        },
        product: { id: "b26f1ba3-2dec-4ce3-b427-1010bdaea6c8" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/bank-of-america-business-advantage-travel",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$0",
            valueTooltip: null,
          },
          {
            description: "Points",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn unlimited 1.5 points for every $1 spent on all purchases everywhere, every time-no matter how much you spend. Points don't expire. Earn 3 points per every dollar spent on travel purchases (car, hotel, airline) booked through the Bank of America® Travel Center.",
                  },
                },
              },
            },
            value: "1.5x-3x",
            valueTooltip:
              "Earn unlimited 1.5 points for every $1 spent on all purchases everywhere, every time-no matter how much you spend. Points don't expire. Earn 3 points per every dollar spent on travel purchases (car, hotel, airline) booked through the Bank of America® Travel Center.",
          },
          {
            description: "Points",
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 30,000 online bonus points after you make at least $3,000 in net purchases in the first 90 days of your account opening which can be redeemed for a $300 statement credit towards travel or dining-related purchases.",
                  },
                },
              },
            },
            value: "30,000",
            valueTooltip:
              "Earn 30,000 online bonus points after you make at least $3,000 in net purchases in the first 90 days of your account opening which can be redeemed for a $300 statement credit towards travel or dining-related purchases.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/5e01a478-944d-4a38-9646-dde7cb8321f0?name=Bank-of-America-WorldPoints-Travel-Rewards-for-Business-Visa&clickHeader_category=Credit+Card&clickHeader_productId=2607&mvt=MTY1MDQwMTg1NjMzODg1NzA5NjoyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOmE5MzQxNGNhNDgwMDRmNTk1NzE0YTVmNGQ0ZGVlYjdiNDkxNmI4N2QxMjY2NGU3MmQyYTAyMzZiOGM3MzBhM2Y%3D",
          text: "Apply now",
          subtext: "on Bank of America's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: null,
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/bank-of-america-business-advantage-travel",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "edbd5a54-b48f-4806-a3f1-e8322ec3ec56",
      categoryName:
        "Best for Travel rewards: Bonus categories + big sign-up offer",
      marketplaceEntity: {
        id: "edbd5a54-b48f-4806-a3f1-e8322ec3ec56",
        productType: "credit_cards",
        name: "Ink Business Preferred® Credit Card",
        displayName: "Ink Business Preferred® Credit Card",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/edbd5a54-b48f-4806-a3f1-e8322ec3ec56/f33406c6b417078eb3356166b6de5bad0fecee4dbabba7229451b1dadb10c195.jpg",
          alt: "Chase Ink Business Preferred Credit Card",
        },
        annualFee: 95,
        starRating: 5,
        institution: {
          id: "2ab43a2f-629a-41a9-b79a-2991eecd4b36",
          name: "Chase",
        },
        product: { id: "514a5d7d-85c0-4fe5-8264-122b0380e516" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/chase-ink-preferred",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$95",
            valueTooltip: null,
          },
          {
            description: "Points",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 3 points per $1 on the first $150,000 spent on travel and select business categories each account anniversary year, Earn 1 point per $1 on all other purchases - with no limit to the amount you can earn",
                  },
                },
              },
            },
            value: "1x-3x",
            valueTooltip:
              "Earn 3 points per $1 on the first $150,000 spent on travel and select business categories each account anniversary year, Earn 1 point per $1 on all other purchases - with no limit to the amount you can earn",
          },
          {
            description: "Points",
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 100k bonus points after you spend $15,000 on purchases in the first 3 months from account opening. That's $1,000 cash back or $1,250 toward travel when redeemed through Chase Ultimate Rewards®",
                  },
                },
              },
            },
            value: "100,000",
            valueTooltip:
              "Earn 100k bonus points after you spend $15,000 on purchases in the first 3 months from account opening. That's $1,000 cash back or $1,250 toward travel when redeemed through Chase Ultimate Rewards®",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/edbd5a54-b48f-4806-a3f1-e8322ec3ec56?name=Chase-Ink-Business-Preferred&clickHeader_category=Credit+Card&clickHeader_productId=3065&mvt=MTY1MDQwMTg1NjM2NjY1MTQwNToyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOmQ0YmFkZWY3ZTlkMjg4MzA0NTIxYWM4Y2Y3MjMzMTNkZTI2ZGE5YzZhYWM4YzU5MDdlNTU0OGJkNmUxNjM5YTQ%3D",
          text: "Apply now",
          subtext: "on Chase's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: null,
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/chase-ink-preferred",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "e104f7be-f567-11eb-ba9c-673507040569",
      categoryName: "Best for Cash back: Flat-rate rewards",
      marketplaceEntity: {
        id: "e104f7be-f567-11eb-ba9c-673507040569",
        productType: "credit_cards",
        name: "Capital One Spark Cash Plus",
        displayName: "Capital One Spark Cash Plus",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/e104f7be-f567-11eb-ba9c-673507040569/94f3149e309dbd4a36ff3204fdec62229faf4b8e45826464cb5c4e84b66b4c75.jpg",
          alt: "Capital One Spark Cash Plus",
        },
        annualFee: 150,
        starRating: 4.4,
        institution: {
          id: "83928a32-4880-40e9-98c7-e6d016f2c087",
          name: "Capital One",
        },
        product: { id: "0228948a-f556-11eb-960e-cfd8c7dc0bb9" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/small-business/capital-one-spark-cash-plus",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$150",
            valueTooltip: null,
          },
          {
            description: "Cashback",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn unlimited 2% cash back on every purchase, everywhere—with no limits or category restrictions",
                  },
                },
              },
            },
            value: "2%",
            valueTooltip:
              "Earn unlimited 2% cash back on every purchase, everywhere—with no limits or category restrictions",
          },
          {
            description: null,
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn up to a $1,000 cash bonus; $500 once you spend $5,000 in the first 3 months, and $500 once you spend $50,000 in the first 6 months of account opening.",
                  },
                },
              },
            },
            value: "$1,000",
            valueTooltip:
              "Earn up to a $1,000 cash bonus; $500 once you spend $5,000 in the first 3 months, and $500 once you spend $50,000 in the first 6 months of account opening.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/e104f7be-f567-11eb-ba9c-673507040569?name=Capital-One-Spark-Cash-Plus&clickHeader_category=Credit+Card&clickHeader_productId=65811&mvt=MTY1MDQwMTg1NjM3MjgwMTQ4NzoyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOjI3OWEwNjllMGJhZmZmZmQ0ODU0ZWM0NjEzMjNkNTA4MWE5ZGNkMGRkNzk4ZDExYmY4NTg1YTIzMjI3Y2MzYWI%3D",
          text: "Apply now",
          subtext: "on Capital One's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: null,
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl: null,
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "96d63f84-3024-4642-ac1e-db59672e35ef",
      categoryName: "Best for Travel rewards: Flat-rate rewards",
      marketplaceEntity: {
        id: "96d63f84-3024-4642-ac1e-db59672e35ef",
        productType: "credit_cards",
        name: "Capital One Spark Miles for Business",
        displayName: "Capital One Spark Miles for Business",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/96d63f84-3024-4642-ac1e-db59672e35ef/07dde553decefbd83b7d124cb8cc51e6aa4b01b0556234694ff746122f57b784.jpg",
          alt: "Capital One Spark Miles Credit Card",
        },
        annualFee: 95,
        starRating: 4.9,
        institution: {
          id: "83928a32-4880-40e9-98c7-e6d016f2c087",
          name: "Capital One",
        },
        product: { id: "f1ba81f6-dbaf-4ff6-b3d0-83f9464784d7" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/capital-one-spark-miles",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$0 intro for the first year, then $95",
            valueTooltip: null,
          },
          {
            description: "Points",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn unlimited 2X miles per dollar on every purchase, everywhere, no limits or category restrictions, and miles won't expire for the life of the account.",
                  },
                },
              },
            },
            value: "2x",
            valueTooltip:
              "Earn unlimited 2X miles per dollar on every purchase, everywhere, no limits or category restrictions, and miles won't expire for the life of the account.",
          },
          {
            description: "Points",
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn a one-time bonus of 50,000 miles – equal to $500 in travel – once you spend $4,500 on purchases within the first 3 months from account opening.",
                  },
                },
              },
            },
            value: "50,000",
            valueTooltip:
              "Earn a one-time bonus of 50,000 miles – equal to $500 in travel – once you spend $4,500 on purchases within the first 3 months from account opening.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/96d63f84-3024-4642-ac1e-db59672e35ef?name=Capital-One-Spark-Miles&clickHeader_category=Credit+Card&clickHeader_productId=11806&mvt=MTY1MDQwMTg1NjM3NTU4ODAxNzoyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOjU1Mjc2NjdiNGVhZTY4OTVlNzMzMWE3ZWMwZjViMWIyMTA1NzMwMzNhOTY4NDE5NGEwMjVmYTk4ZTY0ZGYwYWM%3D",
          text: "Apply now",
          subtext: "on Capital One's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: null,
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/capital-one-spark-miles",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "1db7001c-f3a1-11eb-a9a9-bb98d572d414",
      categoryName: "Best for Balance transfer business card",
      marketplaceEntity: {
        id: "1db7001c-f3a1-11eb-a9a9-bb98d572d414",
        productType: "credit_cards",
        name: "U.S. Bank Business Triple Cash Rewards World Elite™ Mastercard®",
        displayName:
          "U.S. Bank Business Triple Cash Rewards World Elite™ Mastercard®",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/1db7001c-f3a1-11eb-a9a9-bb98d572d414/167c3d2ab5d139e400b3f8072f2a471ab7e75df54bf2f70deafb8ad851305e8b.jpg",
          alt: "U.S. Bank Business Triple Cash Rewards World Elite™ Mastercard",
        },
        annualFee: 0,
        starRating: 4.7,
        institution: {
          id: "09d20f4c-022c-4d8f-ae60-78c540b08371",
          name: "US Bank",
        },
        product: { id: "89754c6a-f39b-11eb-acd4-273cf601a6e2" },
        reviewLink:
          "https://www.nerdwallet.com/card-details/card-name/US-Bank-Business-Triple-Cash-Rewards-World-Elite",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$0",
            valueTooltip: null,
          },
          {
            description: "Cashback",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn 3% cash back on eligible purchases at gas stations, office supply stores, cell phone service providers and restaurants. Earn 1% cash back on all other eligible net purchases",
                  },
                },
              },
            },
            value: "1%-3%",
            valueTooltip:
              "Earn 3% cash back on eligible purchases at gas stations, office supply stores, cell phone service providers and restaurants. Earn 1% cash back on all other eligible net purchases",
          },
          {
            description: null,
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Limited Time Offer: Earn $500 in cash back. Just spend $4500 on the Account Owner's card in the first 150 days of opening your account.",
                  },
                },
              },
            },
            value: "$500",
            valueTooltip:
              "Limited Time Offer: Earn $500 in cash back. Just spend $4500 on the Account Owner's card in the first 150 days of opening your account.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/1db7001c-f3a1-11eb-a9a9-bb98d572d414?name=US-Bank-Business-Triple-Cash-Rewards-World-Elite&clickHeader_category=Credit+Card&clickHeader_productId=65808&mvt=MTY1MDQwMTg1NjM3MDI4MTYyNzoyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOjhjZGFhMWEwMzQwOGM5ODYwNWU0OTE3N2RhMzcyYzhiOTcyN2I3OTkwYWI1ZGIzNGNhYzM3ZjczZGM0NjUzNWM%3D",
          text: "Apply now",
          subtext: "on US Bank's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: {
          link: "https://www.nerdwallet.com/redirect/credit-cards/65808?name=US-Bank-Business-Triple-Cash-Rewards-World-Elite&clickHeader_category=Credit+Card&destination_url_field=terms_and_fees_link&clickHeader_productId=65808",
          text: "Rates & Fees",
          subtext: null,
          type: "APPLY_NOW",
        },
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/card-details/card-name/US-Bank-Business-Triple-Cash-Rewards-World-Elite",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "6405eea3-164d-4489-9e29-c3743831b268",
      categoryName: "Best for Hotel credit card",
      marketplaceEntity: {
        id: "6405eea3-164d-4489-9e29-c3743831b268",
        productType: "credit_cards",
        name: "Marriott Bonvoy Business™ American Express® Card",
        displayName: "Marriott Bonvoy Business™ American Express® Card",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/6405eea3-164d-4489-9e29-c3743831b268/7fbe5d3d734070c01690fc692520e724ea54776f754f518ff613dae77132e5e5.jpg",
          alt: "Marriott Bonvoy Business™ American Express® Card",
        },
        annualFee: 125,
        starRating: 4.1,
        institution: {
          id: "cd471c3b-eca7-487c-9a41-ed802b938fca",
          name: "American Express",
        },
        product: { id: "170713bb-199a-45cb-9b7d-82dd83aa1362" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/marriott-bonvoy-business",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$125",
            valueTooltip: null,
          },
          {
            description: "Points",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "6x points at hotels participating in the Marriott Bonvoy™ program. 4x points at U.S. restaurants, at U.S. gas stations, on wireless telephone services purchased directly from U.S. service providers and on U.S. purchases for shipping. 2x points on all other eligible purchases. Terms Apply.",
                  },
                },
              },
            },
            value: "2x-6x",
            valueTooltip:
              "6x points at hotels participating in the Marriott Bonvoy™ program. 4x points at U.S. restaurants, at U.S. gas stations, on wireless telephone services purchased directly from U.S. service providers and on U.S. purchases for shipping. 2x points on all other eligible purchases. Terms Apply.",
          },
          {
            description: "Points",
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Limited Time Offer: Earn 100,000 Marriott Bonvoy bonus points after you use your new Card to make $5,000 of eligible purchases within the first 3 months of Card Membership. Offer ends 5/18/22. Terms Apply.",
                  },
                },
              },
            },
            value: "100,000",
            valueTooltip:
              "Limited Time Offer: Earn 100,000 Marriott Bonvoy bonus points after you use your new Card to make $5,000 of eligible purchases within the first 3 months of Card Membership. Offer ends 5/18/22. Terms Apply.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/6405eea3-164d-4489-9e29-c3743831b268?name=American-Express-Marriott-Bonvoy-Business&clickHeader_category=Credit+Card&clickHeader_productId=65567&mvt=MTY1MDQwMTg1NjM1NDAyNjc1MToyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOjczODZjYzM2NmU4NTk1NDI1YjVmZjI5ODgxODM1NGE5NGYyZjk5Y2JkNDM4OThmYTMyYWUyZmFkNTY2ZDU1ZmE%3D",
          text: "Apply now",
          subtext: "on American Express's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: {
          link: "https://www.nerdwallet.com/redirect/credit-cards/65567?name=American-Express-Marriott-Bonvoy-Business&clickHeader_category=Credit+Card&destination_url_field=terms_and_fees_link&clickHeader_productId=65567",
          text: "Rates & Fees",
          subtext: null,
          type: "APPLY_NOW",
        },
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/marriott-bonvoy-business",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "b94914ce-fe80-4d11-b855-d8fe46d97f01",
      categoryName: "Best for Airline credit card",
      marketplaceEntity: {
        id: "b94914ce-fe80-4d11-b855-d8fe46d97f01",
        productType: "credit_cards",
        name: "Delta SkyMiles® Reserve Business American Express Card",
        displayName: "Delta SkyMiles® Reserve Business American Express Card",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/b94914ce-fe80-4d11-b855-d8fe46d97f01/47cbf7ac072cdb76976ab4f580fa0adfcd1b9caae8a359bd5843f71618a797a0.jpg",
          alt: "American Express Delta Reserve for Business Credit Card",
        },
        annualFee: 550,
        starRating: 4.9,
        institution: {
          id: "cd471c3b-eca7-487c-9a41-ed802b938fca",
          name: "American Express",
        },
        product: { id: "cf229f9c-95c7-4663-8fa5-00ee6a969a4b" },
        reviewLink:
          "https://www.nerdwallet.com/reviews/credit-cards/delta-skymiles-reserve-business-amex",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$550",
            valueTooltip: null,
          },
          {
            description: "Miles",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "After you spend $150,000 on your Card in a calendar year, you earn 1.5 miles per dollar (that’s an extra half mile per dollar) on eligible purchases the rest of the year. If your purchase qualifies for a category that has a higher mileage accelerator, only the higher accelerator will apply (ex. you would earn 3X miles on purchases made directly with Delta instead of 1.5X). Plus enjoy 3X miles per dollar on all eligible Delta purchases. Terms Apply.",
                  },
                },
              },
            },
            value: "1x-3x",
            valueTooltip:
              "After you spend $150,000 on your Card in a calendar year, you earn 1.5 miles per dollar (that’s an extra half mile per dollar) on eligible purchases the rest of the year. If your purchase qualifies for a category that has a higher mileage accelerator, only the higher accelerator will apply (ex. you would earn 3X miles on purchases made directly with Delta instead of 1.5X). Plus enjoy 3X miles per dollar on all eligible Delta purchases. Terms Apply.",
          },
          {
            description: "Miles",
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Welcome Offer: Earn 60,000 Bonus Miles and 10,000 Medallion® Qualification Miles (MQMs) after you spend $4,000 in purchases on your new Card in your first 3 months. Terms Apply.",
                  },
                },
              },
            },
            value: "60,000",
            valueTooltip:
              "Welcome Offer: Earn 60,000 Bonus Miles and 10,000 Medallion® Qualification Miles (MQMs) after you spend $4,000 in purchases on your new Card in your first 3 months. Terms Apply.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/b94914ce-fe80-4d11-b855-d8fe46d97f01?name=American-Express-Delta-Reserve-for-Business&clickHeader_category=Credit+Card&clickHeader_productId=1101&mvt=MTY1MDQwMTg1NjM0MTk4MTY4NDoyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOmEzMjdhNDNmZDdkYzVkNWQ3ZWZhZjVhODNiMzAzMjUyNzk2MGNkMmU2ODk3MzQ1MTgwMmQwOGJiYmY0ZGEzMGY%3D",
          text: "Apply now",
          subtext: "on American Express's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: {
          link: "https://www.nerdwallet.com/redirect/credit-cards/1101?name=American-Express-Delta-Reserve-for-Business&clickHeader_category=Credit+Card&destination_url_field=terms_and_fees_link&clickHeader_productId=1101",
          text: "Rates & Fees",
          subtext: null,
          type: "APPLY_NOW",
        },
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/blog/credit-cards/delta-reserve-for-business/",
      excludeModelExecutionIds: true,
    },
    {
      marketplaceEntityId: "f2fe19e2-c2cc-419a-aab1-5a36021e5e83",
      categoryName: "Best for No personal guarantee",
      marketplaceEntity: {
        id: "f2fe19e2-c2cc-419a-aab1-5a36021e5e83",
        productType: "credit_cards",
        name: "Brex Card",
        displayName: "Brex Card",
        image: {
          source:
            "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/f2fe19e2-c2cc-419a-aab1-5a36021e5e83/832d5a29cd678b276db4c953e44ddad12c63c6a61b16580850f4f2690981901c.jpg",
          alt: "Brex ",
        },
        annualFee: 0,
        starRating: 3.3,
        institution: {
          id: "a1f0c0fe-3aa2-4b47-902e-c71532dc4c7d",
          name: "Brex",
        },
        product: { id: "900199ff-de23-4198-aa84-ab7ccfb02ecf" },
        reviewLink: "https://www.nerdwallet.com/reviews/credit-cards/brex-card",
        placementDrivers: [
          {
            description: null,
            label: "Annual fee",
            tooltip: null,
            value: "$0",
            valueTooltip: null,
          },
          {
            description: "Points",
            label: "Rewards rate",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Earn points on every dollar spent with industry-leading multipliers: 7x on rideshare, 4x on Brex Travel, 3x on restaurants, 2x on software subscriptions and 1x on all other transactions. Earn 3x Brex Rewards points on all eligible Apple purchases through the link or your Brex dashboard.",
                  },
                },
              },
            },
            value: "1x-7x",
            valueTooltip:
              "Earn points on every dollar spent with industry-leading multipliers: 7x on rideshare, 4x on Brex Travel, 3x on restaurants, 2x on software subscriptions and 1x on all other transactions. Earn 3x Brex Rewards points on all eligible Apple purchases through the link or your Brex dashboard.",
          },
          {
            description: "Points",
            label: "Intro offer",
            tooltip: {
              type: "Container",
              version: "1.0.0",
              props: {
                children: {
                  type: "Type",
                  version: "1.0.0",
                  props: {
                    children:
                      "Get 10,000 points when you spend your first $1,000 on your Brex card, another 10,000 when you spend $3,000 in 3 months on your card, and 20,000 more when you link payroll to your Brex account.",
                  },
                },
              },
            },
            value: "40,000",
            valueTooltip:
              "Get 10,000 points when you spend your first $1,000 on your Brex card, another 10,000 when you spend $3,000 in 3 months on your card, and 20,000 more when you link payroll to your Brex account.",
          },
        ],
        cta: {
          link: "https://www.nerdwallet.com/redirect/f2fe19e2-c2cc-419a-aab1-5a36021e5e83?name=Brex-Commercial-Card&clickHeader_category=Credit+Card&clickHeader_productId=65556&mvt=MTY1MDQwMTg1NjMzNTg3NDk3OToyMTlmZjVhODY4YWVjNjY4NTUzZmM5YmNkYjJiOThhYTY4ZjdlODZlMWE5NGUwMDYtYzlmZC00NmZlLWM2ZWItNTY2ODVmNDM3ZjJjOjhhZGUyMjRjM2FhNTdkNTE2NGYzYmEyNjEyOWM0OGY4OTQ0ZjE1YzZkYjg5NmRmZjg2YTAzZjJhODk1MDNiYzI%3D",
          text: "Apply now",
          subtext: "on Brex's website",
          type: "APPLY_NOW",
        },
        ratesAndFeesCta: null,
      },
      shouldEnableImageLazyLoad: true,
      cta: { link: null },
      customReviewUrl:
        "https://www.nerdwallet.com/reviews/credit-cards/brex-card",
      excludeModelExecutionIds: true,
    },
  ],
}
