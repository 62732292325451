import React from "react"
import PropTypes from "prop-types"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import CreditCardSummaryTableSection from "@src/components/CreditCardSummaryTableSection"
import { summaryTableSection } from "@src/components/CreditCardSummaryTableSection/__fixtures__/CreditCardSummaryTableSection.fixtures"
import TrustbuildingCTA from "@src/components/TrustbuildingCTA"
import { LocationVariantProvider } from "@src/hooks/useLocationVariant"
import DefaultSeparator from "./_component_default_separator"

const FilterableLendersTablePage = ({ location }) => {
  const title = "Credit score filter - Test Page"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "CREDIT_SCORE",
  }

  return (
    <LocationVariantProvider location={location} assignments={{}}>
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <TrustbuildingCTA headerSize="large" />
        <CreditCardSummaryTableSection
          ratingFilter
          annualFeeFilter
          table={summaryTableSection}
          productDescription="business credit cards"
        />
        <DefaultSeparator />
      </Page>
    </LocationVariantProvider>
  )
}

FilterableLendersTablePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default FilterableLendersTablePage
