import React from "react"
import PropTypes from "prop-types"

import Container from "@nerdwallet/react-container"
import styles from "./TableFeatures.module.less"

const TableFeatures = ({ filter, sort, ratingFilter, annualFeeFilter }) => {
  if (!sort && !filter && !ratingFilter && !annualFeeFilter) return null
  return (
    <Container className={styles.wrapper}>
      {filter && <div className={styles.dropdown}>{filter}</div>}
      {(ratingFilter || annualFeeFilter) && (
        <div className={styles.optionButtons}>
          {ratingFilter}
          {annualFeeFilter}
        </div>
      )}
      {sort && <div className={styles.dropdown}>{sort}</div>}
    </Container>
  )
}

TableFeatures.propTypes = {
  filter: PropTypes.node,
  sort: PropTypes.node,
  ratingFilter: PropTypes.node,
  annualFeeFilter: PropTypes.node,
}

export default TableFeatures
