import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

const LocationVariantContext = createContext({})

const normalize = pathname => pathname.replace(/\/$/, "")

const isVariant = (assignments, id, pathname) =>
  (assignments?.[id] || []).includes(normalize(pathname))

export const LocationVariantProvider = ({
  children,
  assignments,
  location,
}) => (
  <LocationVariantContext.Provider
    value={{ assignments, pathname: location.pathname }}
  >
    {children}
  </LocationVariantContext.Provider>
)

LocationVariantProvider.propTypes = {
  children: PropTypes.node.isRequired,
  assignments: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export const LocationVariantConsumer = ({ children, id }) => (
  <LocationVariantContext.Consumer>
    {({ assignments, pathname }) =>
      children(isVariant(assignments, id, pathname))
    }
  </LocationVariantContext.Consumer>
)

LocationVariantConsumer.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

export const useLocationVariant = (id, control, variant) => {
  const { assignments, pathname } = useContext(LocationVariantContext)

  if (!pathname) throw new Error("Missing LocationVariantProvider wrapper.")

  return isVariant(assignments, id, pathname) ? variant : control
}
