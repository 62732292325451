import React from "react"
import PropTypes from "prop-types"
import { Title } from "@nerdwallet/react-typography"

import styles from "./SummaryTableTitle.module.less"

const SummaryTableTitle = ({ isCreditCard, productDescription, rowCount }) => {
  if (!productDescription) {
    throw new Error("Product description is needed for the summary table title")
  }

  const plural = rowCount !== 1
  const title = `Here ${
    plural ? "are" : "is"
  } ${rowCount} ${productDescription}${
    isCreditCard ? " from our partners" : ""
  }`

  return (
    <Title size="large" className={styles.title}>
      {title}
    </Title>
  )
}

SummaryTableTitle.propTypes = {
  isCreditCard: PropTypes.bool,
  productDescription: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

SummaryTableTitle.defaultProps = {
  isCreditCard: false,
}

export default SummaryTableTitle
