import React from "react"

import Type from "@nerdwallet/react-typography/structured-content"

import { DriverCell, EntityCell, StarRatingCell } from "@src/components/Table"
import TrackedCTA from "@src/components/TrackedCTA"
import { RATES_AND_FEES_CTA_TYPE } from "@src/lib/constants"
import { findInPlacementDrivers } from "@src/lib/drivers"
import { matchesMonetizable } from "@src/lib/helpers"

import { PLACEMENT_LABELS } from "./constants"

import styles from "./BuildRow.module.less"

const placementRow = row => {
  if (!row?.value) return null
  return (
    <DriverCell
      description={row.description}
      tooltip={row.tooltip}
      value={row.value}
      valueTooltip={row.valueTooltip}
    />
  )
}

const BuildRow = ({ row, withGoToLink }) => {
  const {
    marketplaceEntityId,
    categoryName,
    marketplaceEntity: {
      productType,
      product,
      institution,
      image,
      displayName,
      name,
      starRating,
      placementDrivers,
      cta,
      ratesAndFeesCta,
      reviewLink,
      monetizable,
    },
  } = row

  const isMonetizable = matchesMonetizable(monetizable)
  const entityName = displayName || name || ""

  const tableRows = {
    key: marketplaceEntityId,
    marketplaceEntity: (
      <EntityCell
        href={cta?.link}
        imgAlt={image.alt}
        imgSrc={image?.source}
        imgWrapperClassName={styles.imageWrapper}
        text={entityName}
        withLinks={withGoToLink}
        ctaType={"CC Summary Card"}
        isMonetizable={isMonetizable}
        subNodes={
          <div className={styles.showOnMobileOnly}>
            {cta?.link && (
              <TrackedCTA
                id={marketplaceEntityId}
                productType={productType}
                product={product}
                institution={institution}
                monetizable={monetizable}
                cta={cta}
                ctaType="CC Summary CTA"
                href={cta.link}
                data-mclick={isMonetizable}
                buttonClassName={styles.ctaSlim}
              />
            )}
          </div>
        }
      />
    ),
    rating: (
      <StarRatingCell
        rating={starRating}
        link={reviewLink}
        categoryName={categoryName}
        ctaType="CC Summary Star Rating"
        entityName={entityName}
        withReadMoreButton={withGoToLink}
      />
    ),
    learnMore: (
      <div className={styles.learnMoreColumn}>
        {cta?.link && (
          <TrackedCTA
            id={marketplaceEntityId}
            productType={productType}
            product={product}
            institution={institution}
            monetizable={monetizable}
            cta={cta}
            ctaType="CC Summary CTA"
            href={cta.link}
          />
        )}
        {ratesAndFeesCta?.link && (
          <a
            href={ratesAndFeesCta.link}
            data-cta-type={RATES_AND_FEES_CTA_TYPE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Type small size="1">
              {ratesAndFeesCta.text || `Rates & Fees`}
            </Type>
          </a>
        )}
      </div>
    ),
  }

  Object.keys(PLACEMENT_LABELS).forEach(id => {
    const placement = findInPlacementDrivers(placementDrivers, id)
    tableRows[id] = placementRow(placement)
  })

  return tableRows
}

export default BuildRow
